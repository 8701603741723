<template>
  <div class="add">
    <el-form class="add-form" ref="form" :model="test" :rules="rules" label-width="100px" label-position="left">
      <el-form-item label="试卷标题" prop="name">
        <el-input clearable :style="{width: '100%'}" v-model="test.name" placeholder="请输入内容"></el-input>
      </el-form-item>
      <el-form-item label="试卷类别" prop="type">
        <el-select v-model="test.type" placeholder="请选择">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="试卷介绍" prop="introduce">
        <el-input
            clearable
            :style="{width: '100%'}"
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="test.introduce">
        </el-input>
      </el-form-item>
      <el-form-item label="试卷分值" prop="score">
        <el-input :style="{width: '100%'}" type="number" placeholder="请输入分值(可选)" v-model="test.score">
        </el-input>
      </el-form-item>
      <el-form-item label="试卷的标签">
        <el-tag
            :key="tag"
            v-for="tag in dynamicTags"
            closable
            :disable-transitions="false"
            @close="handleClose(tag)">
          {{ tag }}
        </el-tag>
        <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
        >
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 新的标签</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="add('form')">创建试卷</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
//引入添加试卷模块
import {addTest} from "@/api/test";
import {options} from "@/config/global";

export default {
  name: "AddTest",
  data() {
    return {
      dynamicTags: [],
      inputVisible: false,
      inputValue: '',
      rules: {
        name: {required: true, message: '请输入试卷名称', trigger: 'blur'},
        score: {required: true, message: '请输入试卷分数', trigger: 'blur'},
        type: {required: true, message: '请输入试卷类别', trigger: 'blur'},
        introduce: {required: true, message: '请输入试卷介绍', trigger: 'blur'},
      },
      options,
      test: {
        userId: this.$store.state.user.id,
        name: "",
        score: "",
        type: "",
        introduce: "",
        tags: ""
      },
    }
  },
  mounted() {
  },
  methods: {
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    // 添加试卷
    add(form) {
      this.$refs[form].validate((valid) => {
        if (!valid) {
          this.$message({
            message: "请填写数据",
            type: "error",
            duration: 2 * 1000
          })
          return false;
        }
      });
      if (this.test.userId === undefined || this.test.userId === "") {
        this.$message({
          message: '请先登录',
          type: "error",
          duration: 2 * 1000
        })
        return false;
      }
      // tags信息成功
      this.test.tags = this.dynamicTags.join(",");
      addTest(this.test).then(result => {
        this.$message({
          message: result.data.test,
          duration: 2 * 1000,
          type: "success"
        })
      }).catch(error => {
        this.$message({
          message: error.message,
          duration: 2 * 1000,
          type: "error"
        })
      })
    }
  },


}
</script>

<style scoped>
.add {
  background-color: #fff;
  margin: 0 auto;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
}

.add-form {
  padding: 20px;
}

/* 动态添加tag*/
.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
